import { render, staticRenderFns } from "./OrderPartMaterials.vue?vue&type=template&id=2feb273b&scoped=true&"
import script from "./OrderPartMaterials.vue?vue&type=script&lang=js&"
export * from "./OrderPartMaterials.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2feb273b",
  null
  
)

export default component.exports