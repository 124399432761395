<template>
  <div class="page-wrapper">
    <BaseTableLoader :loading="isDataLoading">
      <div>
        <BaseTableHeader :title="$t('materials_warehouse')">
          <template v-slot:append>
            <OrderPartMaterialFilter
              :filters="orderPartMaterialFilterParams"
              :default-filters="defaultOrderPartMaterialFilterParams"
              @change="getOrderPartMaterials(1, $event)"
              @reset="getOrderPartMaterials(1, defaultOrderPartMaterialFilterParams)"
            />
          </template>
        </BaseTableHeader>

        <OrderPartMaterialTable
          :rows="orderPartMaterialArray"
          :pagination="orderPartMaterialPagination"
          can-change-location
          can-change-status
          show-order-part-quantity
          show-order-part-material-sell-price
          show-order-part-total-sell-price
          @change-page="getOrderPartMaterials"
          @update="updateOrderPartMaterial"
        />
      </div>
    </BaseTableLoader>
  </div>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import BaseTableLoader from '@/components/base/BaseTableLoader';
import BaseTableHeader from '@/components/base/BaseTableHeader';
import OrderPartMaterialFilter from '@/components/filters/OrderPartMaterialFilter';
import orderPartMaterialService from '@/api/order-part-material-service';
import OrderPartMaterialTable from '@/components/tables/OrderPartMaterialTable';

export default {
  name: 'OrderPartMaterials',

  components: {
    OrderPartMaterialTable,
    OrderPartMaterialFilter,
    BaseTableHeader,
    BaseTableLoader,
  },

  mixins: [crudMixin],

  data() {
    return {
      orderPartMaterialArray: [],
      orderPartMaterialPagination: {
        page: 1,
      },
      orderPartMaterialFormItem: {},
      orderPartMaterialFilterParams: {},
      isOrderPartMaterialFormOpen: false,
      isDataLoading: true,
      newOrderPartMaterialTemplate: {},
      defaultOrderPartMaterialFilterParams: {
        order_part_status: ['not_started', 'prepared', 'in_progress'],
        material_status: ['not_ordered', 'inquired', 'ordered'],
      },
    };
  },

  created() {
    this.orderPartMaterialFilterParams = JSON.parse(
      JSON.stringify(this.defaultOrderPartMaterialFilterParams),
    );
    if (this.$route.query.search) {
      this.orderPartMaterialFilterParams.search = this.$route.query.search;
    }
    this.getOrderPartMaterials(1, this.orderPartMaterialFilterParams);
  },

  methods: {
    getOrderPartMaterials(page, params) {
      this.orderPartMaterialFilterParams = params;
      this.crudMixin_getPage(
        orderPartMaterialService.getPage,
        'orderPartMaterial',
        page,
        this.orderPartMaterialFilterParams,
      );
    },

    async updateOrderPartMaterial(item) {
      const { data } = await this.crudMixin_update(
        orderPartMaterialService.patch,
        'orderPartMaterial',
        item,
      );
      this.crudMixin_updated('orderPartMaterial', data);
    },
  },
};
</script>

<style scoped></style>
