<template>
  <BaseFilterDrawer :selected-filter-count="selectedFilterCount" @reset="filterMixin_resetFilter">
    <v-text-field
      :value="filters.search"
      :label="$t('search')"
      prepend-inner-icon="mdi-magnify"
      clearable
      @input="filterMixin_handleDebounce('search', $event)"
    />

    <div class="label">
      {{ $t('order_part_statuses_label') }}
    </div>
    <v-checkbox
      v-for="(status, index) in orderPartStatuses"
      :input-value="filters.order_part_status || []"
      :key="status.value"
      :label="status.text"
      :value="status.value"
      :class="{ 'mt-0': index === 0 }"
      hide-details
      @change="filterMixin_updateFilter('order_part_status', $event)"
    />

    <div class="label mt-6">
      {{ $t('material_statuses') }}
    </div>
    <v-checkbox
      v-for="(status, index) in materialStatuses"
      :input-value="filters.material_status || []"
      :key="status.value"
      :label="status.text"
      :value="status.value"
      :class="{ 'mt-0': index === 0 }"
      hide-details
      @change="filterMixin_updateFilter('material_status', $event)"
    />
  </BaseFilterDrawer>
</template>

<script>
import { ORDER_PART_STATUSES } from '@/api/order-part-service';
import { ORDER_PART_MATERIAL_STATUSES } from '@/api/order-part-material-service';
import BaseFilterDrawer from '@/components/filters/BaseFilterDrawer';
import filterMixin from '@/mixins/filter-mixin';

export default {
  name: 'OrderPartMaterialFilter',

  components: { BaseFilterDrawer },

  mixins: [filterMixin],

  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    orderPartStatuses() {
      return ORDER_PART_STATUSES;
    },

    materialStatuses() {
      return ORDER_PART_MATERIAL_STATUSES;
    },
  },
};
</script>

<style scoped></style>
